<template>
    <div class="container" style="min-height: 85vh">
        <div class="columns">
            <div class="column is-8 is-offset-2">
                <br />
                <div class="card">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <h1 class="title is-1">
                                    Announcing BossDB data collection DOIs
                                </h1>
                                <p class="subtitle is-6">
                                    by Brock Wester | Updated: October 1, 2023
                                </p>
                                <!-- tags: -->
                                <p class="subtitle is-6">
                                    <span class="tag is-primary">#Open-Data</span>&nbsp;
                                    <span class="tag is-primary">#Publication</span>&nbsp;
                                    <span class="tag is-primary">#Scientific-Communication</span>&nbsp;
                                </p>

                                <hr />
                            </div>
                        </div>
                        <div class="content">
                            <p>
                                I'm pleased to share that BossDB data collections are now assigned Digital Object
                                Identifiers (<a href="https://doi.org/">DOIs</a>), which are stable, persistent identifiers
                                that you can
                                use to point to a BossDB resource in your publication, website, or other communications.
                            </p>
                            <p>
                                BossDB has already supported long-term stable URLs for data collections since 2017 (see our
                                post on
                                <router-link to="/post/open-data">
                                    <span class='tag'>Sharing Open Data with BossDB</span>
                                </router-link>), and those URLs will remain valid in perpetuity. Meanwhile, DOIs are a
                                familiar and widely used identifier for scientific resources, and we are excited to be able
                                to offer them to our users.
                            </p>

                            <p>
                            <h3>What is a DOI?</h3>
                            A DOI is a unique, persistent identifier for a digital object. DOIs are commonly used to
                            identify scientific publications, but they can also be used to identify datasets, software,
                            and other digital objects. DOIs are managed by the <a href="https://www.doi.org/">DOI
                                Foundation</a>, which is a not-for-profit membership organization that
                            maintains the infrastructure for DOIs and provides services to DOI registrants.
                            </p>

                            <p>
                            <h3>How do I get a DOI for my BossDB Data Collection?</h3>
                            We are now retroactively assigning DOIs to all existing BossDB Data Collections, and we will
                            also assign DOIs to new Data Collections as they are created. We expect all Data Collections to
                            have a DOI by the end of 2023.
                            </p>

                            <p>
                            <h3>Can I still use existing BossDB identifiers?</h3>
                            Yes you can! BossDB DOIs will be assigned <i>in addition to</i> BossDB URIs (things that start
                            with <code>bossdb://</code>). You will still be able to use these URIs to refer to and access
                            data collections, and you can still use URLs starting with <code>https://bossdb.org/</code> to
                            link to a data collection or resource from another webpage or publication.
                            </p>

                            <p>
                            <h3>How do I cite a BossDB data collection?</h3>
                            You can now cite a BossDB data collection using its DOI, as well as the long term stable URL.
                            </p>

                            <hr />
                            <p>We're excited to be able to offer DOIs to our users, and we hope that this will make it
                                easier for you to share and cite your BossDB data collections. If you have any questions or
                                feedback, please get in touch with us at <a
                                    href="mailto:info@bossdb.org">info@bossdb.org</a>.
                            </p>
                            <p>
                                &mdash; Brock
                            </p>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div class="card">
                    <div class="card-content">
                        <div class="content">
                            <div class="columns">
                                <div class="column is-narrow">
                                    <figure class="image is-128x128">
                                        <img class="is-rounded" :src="brock_profile">
                                    </figure>
                                </div>
                                <div class="column">
                                    <p>
                                        <strong>Brock Wester, Ph.D.</strong>
                                    <div><span>Principal Investigator, BossDB</span></div>
                                    <br />
                                    <a href="mailto:brock@bossdb.org">
                                        <span class="icon is-small">
                                            <i class="fa fa-envelope"></i>
                                        </span>
                                        <span>&nbsp; brock@bossdb.org</span>
                                    </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <br />
    </div>
</template>


<script>
import { ASSETS_BASE_URL } from '@/assets.js';

export default {
    name: "DOIs",
    data() {
        return { brock_profile: ASSETS_BASE_URL + "team/brock_wester.jpg" };
    },
};

</script>
